@use '../../styles/theme.scss';

.socialsButton, .productsButton {
    background-color: theme.$primary-hex;
    height: 90px;
    margin: .5rem;
    border-radius: theme.$border-radius;

    font-size: 1.125rem;

    display: flex;
    align-items: center;
    
    > p, > div.productsLabel {
        margin-inline: 0 auto;

        > p {
            margin: 0;

            color: theme.$secondary-hex-text;

            &:nth-child(even) {
                margin-top: 0.5rem;
                color: theme.$primary-hex-text;
            }
        }
    }

    > svg {
        margin-inline: 13.5px;
        justify-self: flex-end;
        max-height: 32px;
    }

    &:link, &:visited {
        color:white;
        text-decoration: none;
    }
}

.socialsButton {
    flex-basis: calc(100% / 3 - 1rem);
}

.productsButton {
    flex-basis: calc(100% / 2 - 1rem);

    .productsImg {
        transform: rotate(3deg);
    }
    &:nth-child(even) {
        .productsImg {
            transform: rotate(-3deg);
        }
    }
}

.socialsImg, .productsImg {
    margin: 13.5px;
    height: 63px;
    width: 63px;

    display: flex;
    justify-content: center;
    align-items: center;


    border-radius: theme.$border-radius;
}





.socialsImg > img {
    max-height: 35px;
    max-width: 35px;
}

.productsImg > img {
    max-height: 63px;
    max-width: 63px;
}

@media screen and (max-width: 1049px) {
    .socialsButton {
        flex-basis: calc(100% / 2 - 1rem);
    }

}

@media screen and (max-width: 699px) {
    .socialsButton, .productsButton {
        flex-basis: calc(100% - 1rem);
    }

    .productsButton {
        height: unset;
        min-height: 90px;

        &:nth-child(even) {
            .productsImg {
                transform: rotate(3deg);
            }
        }
    }
}
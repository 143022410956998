@use '../../styles/theme.scss';

#csgo {
    > section {
        padding: 1rem;
        background-color: theme.$primary-hex;
        border-radius: theme.$border-radius;

        h1 {
            margin: 0 0 1.5rem 0;
        }
    }

    > section+section {
        margin-top: 1rem;
    }
}

#csgo-mouse {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h1 {
        flex-basis: 100%;
    }

    div.sectionRow {
        text-align: center;
    }
}

#csgo-crosshair {
    cursor: none;

    div.crosshairHeader {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        h1 {
            // background-color: red;
            flex-basis: 100%;
        }

        img {
            margin-left: 1.25rem;
            align-self: flex-start;
            // background-color: yellow;
            max-height: 1.25rem;
            max-width:  1.25rem;
        }

        canvas {
            // background-color: blue;
            max-height: 22px;
            max-width: 22px;
            // margin: auto;
            margin: 0 0 1.5rem 0;
            z-index: 250;
        }
    }
}

#csgo-crosshair, #csgo-video {
    display: flex;
    flex-wrap: wrap;

    div.sectionRow {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: left;
    }

    div.sectionRow+div.sectionRow {
        margin-top: 0.75rem;
    }
}



@media screen and (max-width: 600px) {
    #csgo-mouse {
        div.sectionRow {
            flex-basis: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: left;
        }

        div.sectionRow+div.sectionRow {
            margin-top: 0.75rem;
        }
    }
}

@media screen and (max-width: 450px) {

    #csgo-mouse, #csgo-crosshair, #csgo-video {
        div.sectionRow {
            display: block;
            text-align: left;
        }
    }
}

div.sectionRow {
    
    p {
        margin: 0;
    }

    p:first-child {
        color: theme.$secondary-hex-text;
    }

    p:last-child {
        color: theme.$primary-hex-text;
    }
}


.copyCrosshair {
    transition: .08s;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
}
@use '../../styles/theme.scss';

div.sectionTitle {
    margin-block: 3rem 2rem;
    height: 1.3rem;
    flex-basis: 100%;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    >img {
        max-width: 1.3rem;
        max-height: 1.3rem;
        margin-left: 0.75rem;
    }

    >p {
        font-size: 1.3rem;
        padding: 0;
        margin: 0 0.75rem;
    }
}

div.sectionHr {
    flex-grow: 1;
    margin: 0;
    background-color: theme.$secondary-hex;
    height: 1px;
}
@use '../../styles/theme.scss';

header {
    margin: 0;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;

    >img,
    >h1 {
        flex-basis: 100%;
    }

    >h1 {
        text-align: center;
        margin-block: 0.5em 0;
    }

    >img {
        max-width: 128px;
        max-height: 128px;
    }

    >div.center>div.header {
        background-color: theme.$primary-hex;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: theme.$border-radius;

        svg {
            margin-right: 10px;
        }

        p {
            margin: 0;
        }
    }
}

div.teams {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyConfirm {
    background-color: theme.$confirmed-hex !important;
    color: theme.$opaque-hex !important;
}
@use '../../styles/theme.scss';

a.team {
    margin-inline: 1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    justify-content: center;

    transition: .08s;

    &:hover {
        transform: scale(1.02);
    }

    &:active {
        transform: scale(0.98);
    }

    img {
        max-height: 26px;
        max-width: 26px;
        margin-inline: 0 0.5rem; 
    }
}

div.vr {
        background-color: theme.$secondary-hex;
        height: 25px;
        width: 0.1px
}
@use '../../styles/theme.scss';

@font-face {
    font-family: cascadia code;
    font-style: normal;
    font-weight: 400;
    src: local('Cascadia Code'), url(https://fonts.cdnfonts.com/s/29131/Cascadia.woff) format('woff')
}

:root, #root, body {
    margin: 0;
    padding: 0;
}

:root {
    color-scheme: dark;
    background-image: url("./back.avif");
    // background-color: #33136D;
    background-size: cover;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    color: white;
    font-family: cascadia code;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div.main-container {
    margin: auto;
    min-height: calc(100vh - 7rem);
    width: min(100% - 2rem, 1000px);
    margin: 6rem auto 1rem;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;

    // background-color: #0f06;
}

header,
main,
footer {
    flex-basis: 100%;
}

div.center {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unselectable {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.grow {
    transition: .08s;

    &:hover {
        transform: scale(1.02);
        background-color: theme.$primary-hex-hover !important;
    }

    &:active {
        transform: scale(0.98);
        background-color: theme.$primary-hex-hover;
    }
}

.pointer {
    cursor: pointer;
}

#toast-container {
    position: fixed;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);

    >p {
        padding: 0.5rem;
        margin: 0;
        border-radius: theme.$border-radius;
        background-color: theme.$confirmed-hex;
        color: black;
    }
}

.toastfade {
    -webkit-animation: toastfade 1.68s linear forwards;
    animation: toastfade 1.68s linear forwards;
}

@keyframes toastfade {
    0%,100% { opacity: 0; }
    4%,96% { opacity: 1; }
}

@-webkit-keyframes toastfade {
    0%,100% { opacity: 0; }
    4%,96% { opacity: 1; }
}


.loader {
    margin-top: 24vmin;
	display: block;
	position: relative;
	width: 50%;
	display: grid;
	place-items: center;

    &::before, &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        width: 6vmin;
        height: 6vmin;
        border-radius: 50%;
        background-color: #000;

    }

    &::before {
        top: calc(50% + 16vmin);
        animation: loader-1 1.3s cubic-bezier(0.06, 0.01, 0.49, 1.18) infinite;
    }

    &::after {
        opacity: 0;
        top: calc(50% - 8vmin);
        animation: loader-2 1.3s cubic-bezier(0.46,-0.1, 0.27, 1.07) 0.2s infinite;
    }
}



@keyframes loader-1 {
	0%, 55%, 100% {
		opacity: 0;
	}
	
	0% {
		transform: scale(0.2);
	}
	
	22% {
		opacity: 1;
	}
	
	33%, 55% {
		transform: scale(1) translateY(-23.75vmin);
	}
}

@keyframes loader-2 {

	100% {
		opacity: 0;
	}
	
	0%, 33% {
		opacity: 1;
	}
	
	0% {
		transform: scale(0);
	}
	
	100% {
		transform: scale(4);
	}
}